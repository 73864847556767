<template>
  <div ref="kit_map">
    <l-map
        :maxBounds="[
                [48.9, 8.716667],
                [49.199444, 8.123056]
                ]"
        style="height: 100%; width: 100%;"
        :center="[center.lat, center.lng]"
        :zoom="zoom"
        @ready="register_map_events"
    >
      <l-tile-layer
          :attribution="'&copy; ' + $t('views.nd.map.OSM_contributers')"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
      <template v-if="enable_kit_campus_plan">
        <l-tile-layer :z-index="2" :options="{bounds:
            [
                [49.019608, 8.365166],
                [49.024113, 8.369146]
            ]
            }" url="https://www.kit.edu/campusplan/public/layers/kit/{z}/{x}/{y}.png"></l-tile-layer>
        <l-tile-layer :z-index="2" :options="{bounds:
            [
                [49.020372, 8.427647],
                [49.024853, 8.434883]
            ]
            }" url="https://www.kit.edu/campusplan/public/layers/kit/{z}/{x}/{y}.png"></l-tile-layer>
        <l-tile-layer :z-index="2" :options="{bounds:
            [
                [49.087723, 8.419273],
                [49.114386, 8.447196]
            ]
            }" url="https://www.kit.edu/campusplan/public/layers/kit/{z}/{x}/{y}.png"></l-tile-layer>
        <l-tile-layer :z-index="2" :options="{bounds:
            [
                [49.019608, 8.365166],
                [49.024113, 8.369146]
            ]
            }" url="https://www.kit.edu/campusplan/public/layers/kit/{z}/{x}/{y}.png"></l-tile-layer>
        <l-tile-layer :z-index="2" :options="{bounds:
            [
                [49.007740, 8.399972],
                [49.019963, 8.428413]
            ]
            }" url="https://www.kit.edu/campusplan/public/layers/kit/{z}/{x}/{y}.png"></l-tile-layer>
      </template>
      <slot name="map_content"></slot>
      <l-control position="bottomleft" style="background: #ffffffaa; padding: 1em; border-radius: 1em;">
        <b-checkbox switch v-model="enable_kit_campus_plan" class="mb-1">KIT Campus Plan</b-checkbox>
        <b-button class="ml-0" @click="map_fullscreen()" variant="secondary" style="background-color: white; color: black;">
          <NetvsIcon icon="minimize" v-if="fullscreen"/>
          <NetvsIcon icon="maximize" v-else/>
        </b-button>
        <div class="mt-1" v-if="click_latlng"><netvs-icon icon="geo_marker" class="mr-1"/>{{click_latlng.lat}},{{click_latlng.lng}}</div>
      </l-control>
    </l-map>
  </div>
</template>

<script>
import {LMap, LTileLayer, LControl} from 'vue2-leaflet'// LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css'
import NetvsIcon from '@/icons/NETVSIcon.vue'
import {latLng} from 'leaflet'

export default {
  name: 'KITMap',
  components: {NetvsIcon, LMap, LTileLayer, LControl},
  props: {
    center: {
      type: Object,
      required: false,
      default: () => {
        return {lat: 49.011563005743405, lng: 8.413672919010981}
      }
    },
    zoom: {
      type: Number,
      required: false,
      default: undefined
    }
  },
  mounted() {
    this.$refs.kit_map.addEventListener('fullscreenchange', (e) => {
      this.fullscreen = !!document.fullscreenElement
    })
  },
  methods: {
    latLng,
    map_fullscreen() {
      if (this.fullscreen) {
        document.exitFullscreen()
      } else {
        this.$refs.kit_map.requestFullscreen()
      }
    },
    register_map_events(map) {
      map.on('click', (e) => {
        window.console.debug(e)
        this.$emit('input', e.latlng)
        this.click_latlng = e.latlng
      })
    }
  },
  data: () => {
    return {
      click_latlng: null,
      enable_kit_campus_plan: true,
      fullscreen: false
    }
  }
}
</script>

<style scoped>
</style>
