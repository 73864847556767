var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"kit_map"},[_c('l-map',{staticStyle:{"height":"100%","width":"100%"},attrs:{"maxBounds":[
              [48.9, 8.716667],
              [49.199444, 8.123056]
              ],"center":[_vm.center.lat, _vm.center.lng],"zoom":_vm.zoom},on:{"ready":_vm.register_map_events}},[_c('l-tile-layer',{attrs:{"attribution":'&copy; ' + _vm.$t('views.nd.map.OSM_contributers'),"url":"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}}),(_vm.enable_kit_campus_plan)?[_c('l-tile-layer',{attrs:{"z-index":2,"options":{bounds:
          [
              [49.019608, 8.365166],
              [49.024113, 8.369146]
          ]
          },"url":"https://www.kit.edu/campusplan/public/layers/kit/{z}/{x}/{y}.png"}}),_c('l-tile-layer',{attrs:{"z-index":2,"options":{bounds:
          [
              [49.020372, 8.427647],
              [49.024853, 8.434883]
          ]
          },"url":"https://www.kit.edu/campusplan/public/layers/kit/{z}/{x}/{y}.png"}}),_c('l-tile-layer',{attrs:{"z-index":2,"options":{bounds:
          [
              [49.087723, 8.419273],
              [49.114386, 8.447196]
          ]
          },"url":"https://www.kit.edu/campusplan/public/layers/kit/{z}/{x}/{y}.png"}}),_c('l-tile-layer',{attrs:{"z-index":2,"options":{bounds:
          [
              [49.019608, 8.365166],
              [49.024113, 8.369146]
          ]
          },"url":"https://www.kit.edu/campusplan/public/layers/kit/{z}/{x}/{y}.png"}}),_c('l-tile-layer',{attrs:{"z-index":2,"options":{bounds:
          [
              [49.007740, 8.399972],
              [49.019963, 8.428413]
          ]
          },"url":"https://www.kit.edu/campusplan/public/layers/kit/{z}/{x}/{y}.png"}})]:_vm._e(),_vm._t("map_content"),_c('l-control',{staticStyle:{"background":"#ffffffaa","padding":"1em","border-radius":"1em"},attrs:{"position":"bottomleft"}},[_c('b-checkbox',{staticClass:"mb-1",attrs:{"switch":""},model:{value:(_vm.enable_kit_campus_plan),callback:function ($$v) {_vm.enable_kit_campus_plan=$$v},expression:"enable_kit_campus_plan"}},[_vm._v("KIT Campus Plan")]),_c('b-button',{staticClass:"ml-0",staticStyle:{"background-color":"white","color":"black"},attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.map_fullscreen()}}},[(_vm.fullscreen)?_c('NetvsIcon',{attrs:{"icon":"minimize"}}):_c('NetvsIcon',{attrs:{"icon":"maximize"}})],1),(_vm.click_latlng)?_c('div',{staticClass:"mt-1"},[_c('netvs-icon',{staticClass:"mr-1",attrs:{"icon":"geo_marker"}}),_vm._v(_vm._s(_vm.click_latlng.lat)+","+_vm._s(_vm.click_latlng.lng))],1):_vm._e()],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }